import './index.scss'

$(document).scroll(function () { 
    // index-banner 首页banner  
    // ny-banner  其他页banner
    // pro-detail-banner 产品详情页banner
    //pro-list-banner 产品页banner
    var indexbanner = $('.index-banner').height()
    var nybanner = $('.ny-banner').height()
    var prodetail = $('.pro-detail-banner').height()
    var prolist = $('.pro-list-banner').height()
    var scroH = $(document).scrollTop();  
    var viewH = $(window).height();        
    var contentH = $(document).height();   
    console.log(indexbanner);
    if(indexbanner){
        if(scroH>indexbanner){
            $('.header_contactus_btn').fadeOut()
        }else{
            $('.header_contactus_btn').fadeIn()
        }
        return false
    }
    if(nybanner){
        if(scroH>nybanner){
            $('.header_contactus_btn').fadeOut()
        }else{
            $('.header_contactus_btn').css({'display':'flex'})
        }
        return false
    }
    if(prodetail){
        if(scroH>prodetail){
            $('.header_contactus_btn').fadeOut()
        }else{
            $('.header_contactus_btn').css({'display':'flex'})
        }
        return false
    }
    if(prolist){
        if(scroH>prolist){
            $('.header_contactus_btn').fadeOut()
        }else{
            $('.header_contactus_btn').css({'display':'flex'})
        }
        return false
    }


    // if(scroH >100){          
    // }         
    // if (contentH - (scroH + viewH) <= 100){                     
    // }           
    // if (contentH = (scroH + viewH)){                    
    // }         
});

//侧栏菜单隐藏显示
$('[data-open="cmenu"]').on('click', function () {
    if ($('header .hamburger').hasClass('active')) {
        $('header .hamburger').removeClass('active')
        $('header .menu_shadow').removeClass('active')
        $('header .open-menu').removeClass('active')
        $('header .fixed-menu .cont').fadeOut(200)
        setTimeout(() => {
            $('header .fixed-menu').removeClass('active')
            $('.common-mask').fadeOut(200)
        }, 150);

    } else {
        $('header .hamburger').addClass('active')
        $('header .menu_shadow').addClass('active')
        $('header .open-menu').addClass('active')
        $('header .fixed-menu').addClass('active')
        $('.common-mask').fadeIn(200)
        setTimeout(() => {
            $('header .fixed-menu .cont').fadeIn(200)
        }, 400);
    }
})
$('header .menu_shadow').on('click', function () {
    $('header .hamburger').removeClass('active')
    $('header .menu_shadow').removeClass('active')
    $('header .open-menu').removeClass('active')
    $('header .fixed-menu .cont').fadeOut(200)
    setTimeout(() => {
        $('header .fixed-menu').removeClass('active')
        $('.common-mask').fadeOut(200)
    }, 150);
})

//点击导航列表展开子项
!(function () {
    $('.fixed-menu ul li').eq(1).addClass('active').find('.sub-nav').slideDown(200)
})()
$('.fixed-menu ul li .nav-title').on('click', function () {
    let $parentsEl = $(this).parents('li')
    let ishasSub = $parentsEl.find('.sub-nav').length > 0
    let isActive = $parentsEl.hasClass('active')

    if (ishasSub) {
        if (isActive) {
            $parentsEl.removeClass('active').find('.sub-nav').slideUp(200)
        } else {
            $parentsEl.siblings().removeClass('active').find('.sub-nav').slideUp(200)
            $parentsEl.addClass('active').find('.sub-nav').slideDown(200)
        }
    }
})

//表单动态横线
// $('.exhibitor-reserve .right-form input').focus(function () {
//     if (!$(this).parents('.form-item').hasClass('active')) {
//         $(this).parents('.form-item').addClass('active')
//     }
// }).blur(function () {
//     if ($(this).val() == '') {
//         $(this).parents('.form-item').removeClass('active')
//     }
// })

