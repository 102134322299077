module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!url) var url = '#';
if (!classname) var classname = '';
;
__p += '\r\n<a href="' +
((__t = ( url )) == null ? '' : __t) +
'" class="common-more ' +
((__t = ( classname )) == null ? '' : __t) +
'">\r\n    <div class="scroll-cont">\r\n        <div><i class="iconfont icon-xiangyou"></i>learn more</div>\r\n        <div><i class="iconfont icon-xiangyou"></i>learn more</div>\r\n    </div>\r\n</a>';

}
return __p
}