module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    if(!bannerPath) var bannerPath='' ;
    if(!title) var title='' ;
    ;
__p += '\r\n    <section class="ny-banner">\r\n        <div class="bg" style="background-image: url(' +
((__t = ( bannerPath)) == null ? '' : __t) +
');"></div>\r\n        <h1 class="title">\r\n            ' +
((__t = ( title)) == null ? '' : __t) +
'\r\n        </h1>\r\n    </section>';

}
return __p
}