
import {WOW} from '../../../node_modules/wowjs/dist/wow';

new WOW().init();



//页面文字圆形旋转效果
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if ($('.common-circle').length > 0){
    //判断页面是上滚还是下滚
    var delta = scroH - beforeScroH;
    if (delta > 0) {
      $('.common-circle').removeClass('reverse')
      $('.common-circle .img').css('transform', 'rotate(' + scroH / 5 +'deg)')
    }
    if (delta < 0) {
      $('.common-circle').addClass('reverse')
      $('.common-circle .img').css('transform', 'rotate(' + scroH / 5 + 'deg)')
    }

    beforeScroH = scroH;
  }
 
});

// 手机端二级导航下拉
let mobnavEl = $('.marketing-common-nav-mob')

if (mobnavEl.length>0){
  $('.marketing-common-nav-mob .title').on('click',function(){
    let hasactive = $(this).hasClass('active')

    if (hasactive){
      $(this).removeClass('active')
      $('.marketing-common-nav-mob .nav').slideUp(200)
    }else{
      $(this).addClass('active')
      $('.marketing-common-nav-mob .nav').slideDown(200)
    }
  })
}