import './index.scss'

$(document).scroll(function () { 
    var scroH = $(document).scrollTop();  
    var viewH = $(window).height();        
    var contentH = $(document).height();       
    
    if ($('.ny-banner').length > 0){
        $('.ny-banner .bg').css('transform', 'translateY(' + scroH / 5 +'px)')
    }
});