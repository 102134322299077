import './index.scss'

// 表单点击提示文字上移
$('.footer .form input,.footer .form textarea').focus(function () {
    if (!$(this).parents('.form-item').hasClass('active')) {
        $(this).parents('.form-item').addClass('active')
    }
}).blur(function () {
    if ($(this).val() == '') {
        $(this).parents('.form-item').removeClass('active')
    }
})

//鼠标经过footer,背景色改变
let colors = ['#1b3993', '#e7380d', '#f9be00', '#fb7c2f','#17abf1']
$('.footer').mouseenter(function(){
    let i = parseInt(colors.length * Math.random())
    $('.footer').css('background-color', colors[i]);
})